import { gql } from '@apollo/client'

export const GET_INVITE_PAGES_BY_SLUGS = gql`
  query ($input: GetInvitePagesBySlugsInput!) {
    getInvitePagesBySlugs(input: $input) {
      invitePages {
        id
        code
        used
        limit
        name
        slug
        copy
      }
      count
    }
  }
`

export const GET_INVITE_PAGES_BY_NAMES = gql`
  query ($input: GetInvitePagesByNameInput!) {
    getInvitePagesByName(input: $input) {
      invitePages {
        id
        code
        used
        limit
        name
        slug
      }
      count
    }
  }
`
export const CREATE_USER_APPLICATION = gql`
  mutation ($input: CreateUserApplicationInput!) {
    createUserApplication(input: $input) {
      message
    }
  }
`

