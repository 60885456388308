import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { Invite } from './page/invite/Invite'
import { PrivacyPolicy } from './PrivacyPolicy'
import { TermsOfService } from './TermsOfService'
import { Index } from './page/index'
import instagram from './assets/instagram.png'
import appstore from './assets/appstore.svg'
import { APPSTORE_LINK, INSTAGRAM_LINK } from './globals'

const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
const invitePageRegex = /\/invite\/.+/
const { pathname } = window.location

const IS_MOBILE = regex.test(navigator.userAgent)
const IS_INVITE_PAGE = pathname.match(invitePageRegex) !== null
const IS_NOT_LEGAL_PAGE = pathname.match(/\/legal\/.+/) === null
const SHOW_ICONS = IS_NOT_LEGAL_PAGE

const showAppstoreIcon = () => {
  if (!IS_MOBILE) {
    return true
  }
  if(IS_MOBILE) {
    if(IS_INVITE_PAGE) {
      return false
    }
    return true
  }
}

function App() {
  const instagramLogoStyle = {
    height: IS_MOBILE ? 25 : 35,
    width: IS_MOBILE ? 25 : 35,
  }
  const appstoreLogoStyle = {
    height: IS_MOBILE ? 25 : 35,
    width: IS_MOBILE ? 80 : 100,
  }
  return (
    <div className="App">
      {SHOW_ICONS && (
        <div className="icons">
          {showAppstoreIcon() &&  (
            <div className="icon" style={appstoreLogoStyle}>
              <a href={APPSTORE_LINK}>
                <img src={appstore} alt="logo" style={appstoreLogoStyle} />
              </a>
            </div>
          )}
          <div className="icon" style={instagramLogoStyle}>
            <a href={INSTAGRAM_LINK}>
              <img src={instagram} alt="logo" style={instagramLogoStyle} />
            </a>
          </div>
        </div>
      )}
      <Routes>
        <Route index element={<Index />} />
        <Route path="invite/*" element={<Invite />} />
        <Route path="legal/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="legal/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </div>
  )
}

export default App
