import { useState } from 'react'
import logo from '../../assets/logo-white.png'
import './index.css'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { CREATE_USER_APPLICATION } from '../invite/utils/gql'

const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
const IS_MOBILE = regex.test(navigator.userAgent)

type CreateUserApplicationResponse = {
  createUserApplication: {
    message: boolean
  }
}
const cache: InMemoryCache = new InMemoryCache({})
const DEV = window.location.hostname.includes('localhost')

const client = new ApolloClient({
  cache,
  uri: DEV ? 'http://localhost:4001/graphql' : 'https://api.test.joinview.co/graphql',
})

const SUCCESS_MESSAGE = 'Request sent! We will get back to you shortly.'

export const Index = () => {
  const videoPath = IS_MOBILE ? '/mobile.mp4' : '/desktop.mp4'
  const [response, setResponse] = useState<string>('')
  const [showForm, setShowForm] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  const onRequestInviteClick = () => {
    setShowForm(true)
  }

  const nameHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const emailHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const onSendRequestClick = async () => {
    try {
      if (name.length > 40) {
        throw new Error('Name can not be longer than 40 characters.')
      }
      if (name.length < 4) {
        throw new Error('Name must be at least 4 characters.')
      }

      // Email validation
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!emailRegex.test(String(email).toLowerCase())) {
        throw new Error('Please enter a valid email address.')
      }

      await client.mutate<CreateUserApplicationResponse>({
        mutation: CREATE_USER_APPLICATION,
        variables: {
          input: {
            email,
            fullName: name,
            instagram: 'not set',
          },
        },
      })
      setResponse(SUCCESS_MESSAGE)
    } catch (e) {
      if (
        e instanceof Error &&
        (e.message === 'Name can not be longer than 40 characters.' || e.message === 'Name must be at least 4 characters.' || e.message === 'Please enter a valid email address.')
      ) {
        return setResponse(e.message)
      }
      return setResponse('Something went wrong. Please try again later.')
    }
  }

  const render = () => {
    switch (showForm) {
      case true:
        return (
          <>
            <img src={logo} className="invite-logo" alt="logo" />
            <input
              type={'text'}
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              onFocus={e => (e.target.placeholder = '')}
              className="App-input"
              onBlur={e => (e.target.placeholder = 'NAME')}
              placeholder="NAME"
              onChange={nameHandleChange}
              style={{ marginBottom: 50, width: IS_MOBILE ? '50%' : '25%' }}
            ></input>
            <input
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              onFocus={e => (e.target.placeholder = '')}
              className="App-input"
              onBlur={e => (e.target.placeholder = 'E-MAIL')}
              type="email"
              placeholder="E-MAIL"
              onChange={emailHandleChange}
              style={{ width: IS_MOBILE ? '50%' : '25%' }}
            ></input>

            <div
              className="request-invite-text"
              style={{ marginBottom: IS_MOBILE ? 20 : 50, marginTop: IS_MOBILE ? 20 : 0, pointerEvents: response === SUCCESS_MESSAGE ? 'none' : 'auto', fontSize: IS_MOBILE ? 14 : 20 }}
              onClick={onSendRequestClick}
            >
              SEND REQUEST
            </div>
            <div className="response" style={{ fontSize: IS_MOBILE ? 14 : 20 }}>
              {response}
            </div>
          </>
        )
      default:
      case false:
        return (
          <>
            <img src={logo} className="invite-logo" alt="logo" />
            <div className="request-invite-text" onClick={onRequestInviteClick}>
              REQUEST INVITE
            </div>
          </>
        )
    }
  }

  return (
    <div>
      <video src={videoPath} className="invite-video" autoPlay muted loop playsInline />
      <div className="content">{render()}</div>
    </div>
  )
}
