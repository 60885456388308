import { ApolloClient, InMemoryCache } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import QRCode from 'react-qr-code'
import logo from '../../assets/logo-white.png'
import AppstoreSvg from '../../assets/appstore.svg'

import './Invite.css'
import { fetchInvitePageBySlug } from './utils/fetchInvitePageBySlug'
import { fetchInvitePageByName } from './utils/fetchInvitePageByName'
import { InvitePage } from './utils/types'
import { APPSTORE_LINK } from '../../globals'

const INVITE_VIDEO_LOADING_CLASS_NAME = 'invite-loading-video'
const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
const IS_MOBILE = regex.test(navigator.userAgent)
const { pathname } = window.location

const cache: InMemoryCache = new InMemoryCache({})
const DEV = window.location.hostname.includes('localhost')
const client = new ApolloClient({
  cache,
  uri: DEV ? 'http://localhost:4001/graphql' : 'https://api.test.joinview.co/graphql',
})

type Texts = {
  one: string
  two: string
  three: string
  four: string
  five: string
  six: string
}

const resolveLimit = (invitePage: InvitePage): number => {
  return invitePage.limit > 1 ? invitePage.limit - 1 : 0
}

const resolveCopy = (invitePage: InvitePage): Texts => {
  const limit = resolveLimit(invitePage)
  const isVipSlug = invitePage.slug.toLowerCase() === 'vip'
  switch (invitePage.copy) {
    case 'VIP_FRIENDLY':
      return {
        one: `WELCOME ${invitePage?.name || ''}`,
        two: 'YOU ARE EXCLUSIVELY INVITED TO EXPERIENCE VIEW',
        three: 'THE FRONT ROW OF ONLINE FASHION',
        four: `YOUR PERSONAL INVITE CODE: ${invitePage.code}`,
        five: `SHAREABLE WITH ${isVipSlug ? 5 : limit} FRIENDS`,
        six: IS_MOBILE ? '' : 'SCAN THE CODE TO DOWNLOAD THE APP',
      }
    case 'PUBLIC':
    default:
      return {
        one: `CONGRATULATIONS ${invitePage?.name || ''}`,
        two: 'YOU HAVE JUST GAINED ACCESS TO VIEW',
        three: 'THE FRONT ROW OF ONLINE FASHION',
        four: `YOUR PERSONAL INVITE CODE: ${invitePage.code}`,
        five: IS_MOBILE ? '' : 'SCAN THE CODE TO DOWNLOAD THE APP',
        six: '',
      }
  }
}

export const Invite = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [canStillBeUsed, setCanStillBeUsed] = useState<boolean>(true)
  const [hasAccess, setHasAccess] = useState(false)
  const [invitePage, setInvitePage] = useState<InvitePage | null>(null)
  const username = pathname.split('/')[2]
  const [texts, setTexts] = useState<Texts>({
    one: '',
    two: '',
    three: '',
    four: '',
    five: '',
    six: '',
  })

  useEffect(() => {
    const fetchInvitePage = async () => {
      try {
        setLoading(true)
        const validInvitePage = await fetchInvitePageBySlug({ client, slug: username })
        if (validInvitePage) {
          const limit = resolveLimit(validInvitePage)
          const used = validInvitePage.used
          setCanStillBeUsed(limit >= used)
          setInvitePage(validInvitePage)
          setTexts(resolveCopy(validInvitePage))
        } else {
          return setHasAccess(false)
        }
        setHasAccess(true)
      } catch {
        setHasAccess(false)
        setLoading(false)
      }
    }
    fetchInvitePage()
  }, [username])

  const onEnded = () => {
    setLoading(false)
  }

  const onCanPlay = () => {
    const element = document.querySelector<HTMLVideoElement>(`.${INVITE_VIDEO_LOADING_CLASS_NAME}`)
    if (element) {
      setTimeout(() => {
        element.play()
      }, 1000)
    }
  }

  const canRenderInviteInfo = hasAccess && !loading && canStillBeUsed
  const invalidLink = !hasAccess && !loading
  const videoPath = IS_MOBILE ? '/mobile.mp4' : '/desktop.mp4'
  const showQRCode = IS_MOBILE ? false : true

  const showInvite = !!username

  const renderCTA = () => {
    switch (showQRCode) {
      case true:
        return <QRCode className="qr-code" size={256} value={APPSTORE_LINK} viewBox={`0 0 256 256`} bgColor="transparent" fgColor="white" />
      case false:
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <a href={APPSTORE_LINK} className="invite-link">
              <div>DOWNLOAD THE APP</div>
              <img src={AppstoreSvg} className="invite-appstore" alt="appstore" style={{ height: 50, width: 100 }} />
            </a>
          </div>
        )
    }
  }

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [inviteError, setInviteError] = useState('')

  const onChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value)
  }

  const onChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value)
  }

  const onSubmit = async () => {
    try {
      console.log(window.location)
      const validInvitePage = await fetchInvitePageByName({ client, name: `${firstName.trim()} ${lastName.trim()}`, firstName, lastName })
      if (validInvitePage) {
        window.location.href = `${window.location.origin}/invite/${validInvitePage.slug}`
      } else {
        setInviteError('Could not find a valid invite, please try again later')
      }
    } catch {
      setInviteError('Could not find a valid invite, please try again later')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit()
    }
  }

  const renderContent = () => {
    switch (showInvite) {
      case true: {
        return (
          <>
            {canRenderInviteInfo && (
              <div className="container">
                <video src={videoPath} className="invite-video" autoPlay muted loop playsInline />
                <div className="invite-texts" style={{ width: IS_MOBILE ? '84%' : '100%', left: IS_MOBILE ? '8%' : 0, fontSize: IS_MOBILE ? 11 : 13 }}>
                  <div className="invite-text one">{texts.one}</div>
                  <div className="invite-text two">{texts.two}</div>
                  <div className="invite-text three">{texts.three}</div>
                  <img src={logo} className="invite-logo" alt="logo" style={{ margin: IS_MOBILE ? '7vmin 0' : '5vmin 0' }} />
                  <div className="invite-text four" style={{ marginBottom: invitePage?.copy === 'VIP_FRIENDLY' ? 10 : 0 }}>
                    {texts.four}
                  </div>
                  {invitePage?.copy === 'PUBLIC' && (
                    <div className="cta-wrapper" style={{ maxWidth: IS_MOBILE ? 'auto' : 120, margin: IS_MOBILE ? '20px 0 20px 0' : '20px auto' }}>
                      {renderCTA()}
                    </div>
                  )}
                  <div className="invite-text five">{texts.five}</div>
                  {invitePage?.copy === 'VIP_FRIENDLY' && (
                    <div className="cta-wrapper" style={{ maxWidth: IS_MOBILE ? 'auto' : 120, margin: IS_MOBILE ? '20px 0 20px 0' : '20px auto' }}>
                      {renderCTA()}
                    </div>
                  )}
                  <div className="invite-text six">{texts.six}</div>
                </div>
              </div>
            )}
            {((!canStillBeUsed && !loading) || invalidLink) && (
              <div className="container">
                <video src={videoPath} className="invite-video" autoPlay muted loop playsInline />
                <div className="invite-texts" style={{ width: IS_MOBILE ? '84%' : '100%', left: IS_MOBILE ? '8%' : 0, fontSize: IS_MOBILE ? 11 : 13 }}>
                  <img src={logo} className="invite-logo" alt="logo" style={{ margin: IS_MOBILE ? '7vmin 0' : '5vmin 0' }} />
                  <div className="invite-text one">OOPS!</div>
                  {invalidLink && <div className="invite-text one">IT SEEMS LIKE YOU GOT LOST</div>}
                  {!canStillBeUsed && !loading && <div className="invite-text two">IT SEEMS YOU DIDNT FIND THIS CODE FAST ENOUGH</div>}
                  {!canStillBeUsed && !loading && <div className="invite-text two">GO FIND ANOTHER POSTER TO GET YOUR ACCESS</div>}
                  {!canStillBeUsed && !loading && <div className="invite-text two">BEST OF LUCK!</div>}
                </div>
              </div>
            )}
            {loading && (
              <div className="invite-loading-container">
                <video src={'/intro_view.mp4'} className={INVITE_VIDEO_LOADING_CLASS_NAME} playsInline autoPlay muted onEnded={onEnded} onError={onEnded} onCanPlay={onCanPlay} />
              </div>
            )}
          </>
        )
      }
      case false: {
        return (
          <div className="invite-texts">
            <img src={logo} className="App-logo" alt="logo" />
            <input
              type={'text'}
              onChange={onChangeFirstName}
              value={firstName}
              className="App-input"
              onKeyDown={handleKeyDown}
              onFocus={e => (e.target.placeholder = '')}
              onBlur={e => (e.target.placeholder = 'First Name')}
              placeholder="First Name"
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              style={{ width: IS_MOBILE ? '50%' : '25%' }}
            />
            <input
              type={'text'}
              onChange={onChangeLastName}
              value={lastName}
              className="App-input"
              onKeyDown={handleKeyDown}
              onFocus={e => (e.target.placeholder = '')}
              onBlur={e => (e.target.placeholder = 'Last Name')}
              placeholder="Last Name"
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              style={{ width: IS_MOBILE ? '50%' : '25%' }}
            />
            <button onClick={onSubmit} className="App-button">
              ENTER
            </button>
            <div className="invite-error-text">{inviteError}</div>
          </div>
        )
      }
      default:
        return null
    }
  }

  return <div className="Invite">{renderContent()}</div>
}
