import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { InvitePage } from './types'
import { GET_INVITE_PAGES_BY_SLUGS } from './gql'

export type FetchInvitePageBySlugParams = {
  client: ApolloClient<NormalizedCacheObject>
  slug: string
}

export type FetchInvitePagesBySlugsResponse = {
  getInvitePagesBySlugs: {
    invitePages: InvitePage[]
    count: number
  }
}

export const fetchInvitePageBySlug = async ({ client, slug }: FetchInvitePageBySlugParams): Promise<InvitePage> => {
  const { data } = await client.query<FetchInvitePagesBySlugsResponse>({
    query: GET_INVITE_PAGES_BY_SLUGS,
    variables: {
      input: {
        slugs: [slug],
      },
    },
  })
  return data.getInvitePagesBySlugs.invitePages?.[0]
}
