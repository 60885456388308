import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { InvitePage } from './types'
import { GET_INVITE_PAGES_BY_NAMES } from './gql'

export type FetchInvitePageByNameParams = {
  client: ApolloClient<NormalizedCacheObject>
  name: string
  firstName: string
  lastName: string
}

export type FetchInvitePagesByNameResponse = {
  getInvitePagesByName: {
    invitePages: InvitePage[]
    count: number
  }
}

const findCorrectInvitePage = (invitePages: InvitePage[], name: string, firstName: string, lastName: string): InvitePage => {
  const fixedFirstName = firstName.trim().toLowerCase()
  const fixedLastName = lastName.trim().toLowerCase()
  const fullName = `${fixedFirstName} ${fixedLastName}`
  const validInvitePage = invitePages.find(i => i.name && i.name.toLowerCase() === fullName)
  if (!validInvitePage) {
    throw new Error(`Invite page with name ${name} not found`)
  }
  return validInvitePage
}

export const fetchInvitePageByName = async ({ client, name, firstName, lastName }: FetchInvitePageByNameParams): Promise<InvitePage> => {
  const { data } = await client.query<FetchInvitePagesByNameResponse>({
    query: GET_INVITE_PAGES_BY_NAMES,
    variables: {
      input: {
        name,
      },
    },
  })
  return findCorrectInvitePage(data.getInvitePagesByName.invitePages, name, firstName, lastName)
}
