import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'

import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'

ReactGA.initialize('G-GGRN11RE8H')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)
